import { MediaData } from '../Global/DataUtils';
import { GuidesPageComponents } from '../GuidesAndManuals';
import {
    ParsedDocument,
    ParsedQuickTile,
    QuickTile,
    SupportVideo,
} from './productSupportTypes';

export function extractDocImage(
    documents: ParsedDocument[]
): MediaData | null | undefined {
    for (let i = 0; i < documents.length; i++) {
        if (documents[i].image) {
            return documents[i].image;
        }
    }

    return null;
}

export function extractQuickTiles(
    quickTiles: QuickTile[]
): ParsedQuickTile[] | null {
    if (!quickTiles) {
        return null;
    }

    return quickTiles.map((tile) => {
        const type = tile?.elements?.asset?.value[0]?.__typename;
        const asset = tile?.elements?.asset?.value[0];
        const linkedItem = tile?.elements?.linked_items?.value[0];
        let imageUrl, imageDescription, linkUrl;

        if (asset?.__typename === 'kontent_item_media___icon') {
            imageUrl = asset.elements?.icon_svg_file?.value[0]?.url;
            imageDescription =
                asset.elements?.icon_svg_file?.value[0]?.description;
        }

        if (asset?.__typename === 'kontent_item_media___image') {
            imageUrl = asset.elements?.file?.value[0]?.url;
            imageDescription = asset.elements?.file?.value[0]?.description;
        }

        if (linkedItem.__typename === 'kontent_item_documents___products') {
            linkUrl = linkedItem?.elements?.file?.value[0]?.url;
        }

        if (linkedItem.__typename === 'kontent_item_layout___master_page') {
            linkUrl = linkedItem?.elements?.slug?.value;
        }

        return {
            image: {
                type,
                url: imageUrl,
                description: imageDescription,
            },
            label: tile?.elements?.title?.value,
            url: linkUrl,
        };
    });
}

export function extractVideos(videos: SupportVideo[]) {
    return videos.map((video) => {
        const image =
            video?.elements?.image?.value[0]?.elements?.file?.value[0];

        return {
            caption: video?.elements?.caption?.value,
            image,
            videoUrl: video?.elements?.youtube_url?.value,
        };
    });
}

// Returns first gallery image from Product specifications component that is not empty
export function extractSpecificationsImage(
    pageComponents: GuidesPageComponents
) {
    const specsComponent = pageComponents?.value?.find(
        (pc) =>
            pc.__typename === 'kontent_item_component___product_specifications'
    );
    if (!specsComponent) {
        return '';
    }
    const galleryImages = specsComponent?.elements?.gallery_images?.value;
    if (!galleryImages?.length) {
        return '';
    }

    for (let i = 0; i < galleryImages.length; i++) {
        if (galleryImages[i].elements?.file?.value[0]?.url) {
            return {
                url: galleryImages[i].elements?.file?.value[0]?.url,
                description:
                    galleryImages[i].elements?.file?.value[0]?.description,
                name: galleryImages[i].elements?.file?.value[0]?.name,
            };
        }
    }

    return '';
}
