// extracted by mini-css-extract-plugin
export var container = "what-sauna-module--container--dff98";
export var content = "what-sauna-module--content--24ca1";
export var contentWrapper = "what-sauna-module--contentWrapper--f10c3";
export var imageLabel = "what-sauna-module--imageLabel--31748";
export var largeLabel = "what-sauna-module--largeLabel--d08c7";
export var quiz = "what-sauna-module--quiz--f14fe";
export var roomSelection = "what-sauna-module--roomSelection--349e9";
export var section = "what-sauna-module--section--f6df0";
export var selection = "what-sauna-module--selection--28bba";
export var selections = "what-sauna-module--selections--cc63f";
export var smallLabel = "what-sauna-module--smallLabel--02260";