import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import WhatSaunaForm from './WhatSaunaForm';
import {
    KontentStringValue,
    KontentGatsbyTransformerImage,
} from '../../../types/KontentProps';
import { useProducts } from '../Global/DataUtils/productQueries';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
interface WellnessType {
    codename: string;
    name: string;
    img: string;
}

interface WellnessQueryResults {
    allKontentItemMediaWellnessImage: {
        edges: {
            node: {
                elements: {
                    image: {
                        value: {
                            url: string;
                            fluid?: KontentGatsbyTransformerImage;
                        }[];
                    };
                    wellness_type: {
                        value: {
                            codename: string;
                        }[];
                    };
                };
            };
        }[];
    };
}

interface CapacityTag {
    value: {
        codename: string;
        name: string;
    }[];
}

export interface WhatSaunaProps {
    headline: KontentStringValue;
    description_copy: KontentStringValue;
    interested_in_subheadline: KontentStringValue;
    anchor_name: KontentStringValue;
    contact_form: KontentStringValue;
    capacity_need_subheadline: KontentStringValue;
    cta_label: KontentStringValue;
    results_headline: KontentStringValue;
    wellness_type: {
        value: WellnessType[];
    };
    wellness_type_for_german_site: {
        value: WellnessType[];
    };
    capacity_tag: CapacityTag;
    capacity_tag_for_german_site: CapacityTag;
}

const WhatSaunaContainer: React.FC<WhatSaunaProps> = ({ ...props }) => {
    let { wellness_type, ...rest } = props;
    const pageMetadata = useContext(PageMetadataContext);
    const allProducts = useProducts().filter(
        (product) =>
            product.preferred_language == pageMetadata.preferredLanguage
    );
    ///add wellnesstypeGe here in querry

    const wellnessImagesQueryResults = useStaticQuery(graphql`
        query WellnessImageQuery {
            allKontentItemMediaWellnessImage {
                edges {
                    node {
                        elements {
                            image {
                                value {
                                    url
                                    fluid(maxWidth: 152, quality: 100) {
                                        ...KontentAssetFluid
                                    }
                                }
                            }
                            wellness_type {
                                value {
                                    codename
                                }
                            }
                            wellness_type___de_de {
                                value {
                                    codename
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    wellness_type = addWellnessImages(
        wellness_type?.value,
        wellnessImagesQueryResults
    );

    return (
        <WhatSaunaForm
            {...rest}
            wellness_type={wellness_type}
            allProducts={allProducts}
        />
    );
};

export default WhatSaunaContainer;

function addWellnessImages(
    wellnessTypeValues: WellnessType[],
    wellnessImageQueryResults: WellnessQueryResults
) {
    const wellnessImages =
        wellnessImageQueryResults?.allKontentItemMediaWellnessImage?.edges?.map(
            (result) => {
                return {
                    img: result?.node?.elements?.image?.value[0]?.fluid,
                    codename:
                        result?.node?.elements?.wellness_type?.value[0]
                            ?.codename,
                };
            }
        );

    const wellnessTypeWithImages = wellnessTypeValues?.map((wt) => {
        const wellnessTypeImage = wellnessImages.find((wellnessImage) => {
            return wt.codename === wellnessImage.codename;
        });
        wt.img = wellnessTypeImage?.img || '';

        return wt;
    });

    return { value: wellnessTypeWithImages };
}

export const fragmentWhatSaunaIsRightForYouForm = graphql`
    fragment WhatSaunaIsRightForYouForm on kontent_item_component___what_sauna_is_right_for_you {
        elements {
            headline {
                value
            }
            description_copy {
                ...kontentRichText
            }
            interested_in_subheadline {
                value
            }
            cta_label {
                value
            }
            anchor_name {
                value
            }
            contact_form {
                value
            }
            capacity_need_subheadline {
                value
            }
            capacity_tag {
                value {
                    codename
                    name
                }
            }
            capacity_tag_for_german_site {
                value {
                    codename
                    name
                }
            }
            wellness_type {
                value {
                    codename
                    name
                }
            }
            wellness_type_for_german_site {
                value {
                    codename
                    name
                }
            }
            background_image {
                ...kontentImage
            }
            results_headline {
                value
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            show_2nd_cta {
                value {
                    codename
                }
            }
        }
    }
`;
